import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
  let email, name

  const submit = e => {
    e.preventDefault()

    return (
      email &&
      name &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value,
        NAME: name.value,
      })
    )
  }

  return (
    <>
      <h2>የደብዳቤ መላኪያ ዝርዝሩን ይቀላቀሉ</h2>
      <Form>
        {status === 'sending' && (
          <div style={{ color: 'blue' }}>sending...</div>
        )}
        {status === 'error' && (
          <div
            style={{ color: 'red' }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === 'success' && (
          <div
            style={{ color: 'green' }}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}

        <Form.Group controlId="formBasicEmail">
          <Form.Control
            type="email"
            placeholder="የኢሜል አድራሻ ያስገቡ"
            size="lg"
            ref={node => (email = node)}
          />
        </Form.Group>

        <Form.Group controlId="formName">
          <Form.Control
            type="text"
            placeholder="ስም"
            size="lg"
            ref={node => (name = node)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" onClick={submit}>
          ይላኩ
        </Button>
      </Form>
    </>
  )

  return (
    <div
      style={{
        background: '#efefef',
        borderRadius: 2,
        padding: 10,
        display: 'inline-block',
      }}
    ></div>
  )
}

class MailchimpForm extends Component {
  render() {
    const url =
      'https://medemer.us3.list-manage.com/subscribe/post?u=417fe355351eae4ae7599f9c6&id=a3603346f2'

    return (
      <section className="small medemer-action">
        <div className="container">
          <div className="row featurette">
            <div className="col-md-7">
              <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                  <CustomForm
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                  />
                )}
              />
            </div>
            <div className="col-md-5">&nbsp;</div>
          </div>
        </div>
      </section>
    )
  }
}

export default MailchimpForm
