import { graphql } from 'gatsby'
import React from 'react'
import { Container } from 'react-bootstrap'
import get from 'lodash/get'

import Post from 'templates/post'
import Meta from 'components/meta'
import Layout from 'components/layout'
import Slider from 'components/carousel/am'
import GenerationMedemerBook from 'components/generation-medemer-book/am'
import Book from 'components/book/am'
import Medemer from 'components/medemer/am'
import MailchimpForm from 'components/mailchimp/am'

const BlogIndex = ({ data, location }) => {
  const posts = get(data, 'remark.posts')
  return (
    <Layout location={location}>
      <Meta site={get(data, 'site.meta')} /> <Slider />
      <GenerationMedemerBook />
      <Book />
      <Medemer />
      <MailchimpForm />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query AmIndexQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
      }
    }
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            tags
            description
            date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
